/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    init_bulk_purchase();
    update_breadcrumb();

    $(window).resize(function() {
        update_breadcrumb();
    });

    $('#brands_listing .letter-btn').on('click', function(e) {
        e.preventDefault();

        var letter = $(this).data('letter');
        $('html, body').animate({
            scrollTop: $('#brands_listing .letter-brands-wrp[data-letter-section="' + letter + '"]').offset().top - 70
        }, 'slow');
    });

    $(".allownumericwithoutdecimal").on("keypress keyup blur",function (event) {
        $(this).val($(this).val().replace(/[^\d].+/, ""));
        if ((event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });
});


function init_bulk_purchase() {
    $(document).on('click', '.act-open-bulkform', function(e) {
        e.preventDefault();
        let title = $(this).data('title');
        let data = {
            itemcode : $(this).data('code')
        }
        ajaxnav({
            url: document.location.href,
            data: data,
            template: 'content_types/products/bulk_form',
            callback: function(html) {
                doModal2(html, title);
            }
        });
    })

    function doModal2(content, title) {
        html =  '<div id="dynamicModal2" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
        html += '<div class="modal-dialog modal-dialog-centered">';
        html += '<div class="modal-content">';
        html += '<div class="modal-header">';
        html += '<h4 class="modal-title" id="myModalLabel">' + title + '</h4>';
        html += '<button type="button" class="btn-close" data-dismiss="modal"></button>';
        html += '</div>';
        html += '<div class="modal-body">';
        html += content;
        html += '</div>'; 	// body
        html += '</div>';  	// content
        html += '</div>';  	// dialog
        html += '</div>';  	// modal
        $('body').append(html);
        $("#dynamicModal2").modal();
        $("#dynamicModal2").modal('show');

        $('#dynamicModal2 .btn-close').on('click', function (e) {
            $("#dynamicModal2").modal('hide');
            $("#dynamicModal2").remove();
        });
    }
}